.divider {
	display: block;
	margin-top: 4em;
	margin-bottom: 1em;
	margin-left: auto;
	margin-right: auto;
	width: 50%;
	border: 1px solid #dfdfdf;
}

.room h3 {
	margin: 0 0 1em 0;
}

.room img.openHoursImg {
	display: block;
	margin: 0 auto;
	width: 90%;
}

.room a {
	color: black;
}

.room a:hover {
	color: #EA5B0C;
}

.emptyPage {
	text-align: center;
	font-size: small;
}

.titleCardsContainer {
	column-gap: 0;
	row-gap: 20px;
}