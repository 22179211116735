button.button {
	display: block;
	margin: 1em auto;
	width: fit-content;
	padding: 8px 20px;
	border-radius: 4px;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.05);;
	border: none;
	font-size: 18px;
}

button.button:hover {
	cursor: pointer;
	background-color: #e0e0e0;
}

button.accent {
	background-color: #2a3239;
	color: white;
}