.container {
	margin: 2em 0;
	width: 100%;
}

.carousel {
  position: relative;
  display: block;
	margin: 0 auto;
  justify-content: center;
  align-items: center;
  width: 90%;
	height: auto;
}

.img {
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  width: 100%;
  height: 100%;
}

.imgHidden {
  display: none;
}

.arrow {
  position: absolute;
  filter: drop-shadow(0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.05));
  width: 2rem;
  height: 2rem;
  color: white;
}

.arrow:hover {
  cursor: pointer;
	color: #dfdfdf;
}

.arrowLeft {
  left: 1rem;
	top: 50%;
}

.arrowRight {
	right: 1rem;
	top: 50%;
}