.bgRed {
	background-color: #E30613;
	height: 3px;
}

.bgOrange {
	background-color: #EA5B0C;
	height: 3px;
}

.bgYellow {
	background-color: #FFDE00;
	height: 3px;
}

.bgGreen {
	background-color: #00983A;
	height: 3px;
}

.bgBlue {
	background-color: #27348B;
	height: 3px;
}

.bgPurple {
	background-color: #83368C;
	height: 3px;
}
