.logoContainer {
	margin-top: 50px;
	margin-bottom: 50px;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.logo {
	width: 400px;
	height: auto;
	animation-name: rotation;
  animation-duration: 2s;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;
  animation-timing-function: ease;
}

.logoName {
	display: block;
	text-align: center;
	font-size: 100px;
	margin-top: 20px;
	animation-name: fade-in-text ;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-timing-function:ease-in;
	z-index: -1;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fade-in-text {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.containerText {
	display: block;
	background-color: #dfdfdf;
}

.summary li {
	list-style: none;
	margin: 1em;
}

.summary .text {
	padding: 2em;
	text-align: center;
}

.summary .emphasis {
	font-size: 1.2em;
}

.partners {
	text-align: center;
	margin: 100px;
}

.partners img {
	display: inline-block;
	margin: 20px 100px;
	height:50px;
}

.mediaContainer {
	display: flex;
	justify-content: center;
}

.instagram {
	margin: 2em 1em;
	min-width: 200px;
	overflow: hidden;
	z-index: 0;
}

.live {
	background-color: #dfdfdf;
}

.live h2 {
	text-align: center;
	padding-top: 1em;
}

.live .text {
		padding: 2em;
		text-align: center;
}

.center {
	text-align: center;
}

.divider {
	display: block;
	margin-bottom: 2em;
	margin-left: auto;
	margin-right: auto;
	width: 50%;
	border: 1px solid #dfdfdf;
}

.link {
	color: inherit;
}

.link:hover {
	color: #FFDE00;
}

@media screen and (max-width: 700px) {
	.partners {
		margin: 0 0;
	}
	
	.partners img.aep {
		display: inline-block;
		width: 60%;
		height: auto;
		margin: 40px;
	}

	.partners img.engiqueers {
		display: inline-block;
		width: 30%;
		height: auto;
		margin: 40px;
	}

	.mediaContainer {
		flex-direction: column;
		margin: 0 0;
	}

	.instagram {
		margin: 1em 1em;
	}
}

@media screen and (max-width: 640px) {
	.logo {
		width: 300px;
	}

	.logoName {
		font-size: 70px;
	}
}

@media screen and (max-width: 380px) {
	.logo {
		width: 200px;
	}

	.logoName {
		font-size: 50px;
	}
}