.container {
	position: relative;
	border: solid 1px #dfdfdf;
	border-radius: 4px;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
	padding: 1em;
	text-align: center;
	line-height: 1.5em;
	z-index: 0;
}

.container h4 {
	font-size: 18px;
}

.container h5 {
	font-weight: normal;
	font-size: 16px;
}

.container img {
	border-radius: 14px;
	padding: 0.5em;
	width: 100%;
}

.container a  {
	font-size: 26px;
	color:white;
}

.container a:hover {
	color:#00983A;
}

.container .linkedin {
	position: absolute;
	top: 40px;
	right: 40px;
}

.visible {
	position: absolute;
	display: block;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
	color: white;
	border-radius: 4px;
	padding: 1em;
	margin: 0.3em;
	bottom: 0;
	left: 0;
	font-size: 14px;
	animation-name: fade-in-up;
  animation-duration: 0.4s;
}

.hidden {
	display: none;
}

.descriptionPreview {
	font-size: small;
	font-style: italic;
}

.descriptionPreview hr {
	margin: 1em 0;
}

.alert {
	font-weight: bold;
	color: red;
	margin: 1em 0;
}

.formContainer {
	border: solid 1px #dfdfdf;
	border-radius: 4px;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
	padding: 1em;
}

.form input,
.form textarea {
	display: block;
	margin: 10px auto;
	font-size: 18px;
	border-radius: 4px;
	border: solid 1px #dfdfdf;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
	padding: 0.2em;
}

.form textarea {
	font-family: inherit;
}

@keyframes fade-in-up {
  from {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}