.divider {
	display: block;
	margin-top: 4em;
	margin-bottom: 1em;
	margin-left: auto;
	margin-right: auto;
	width: 50%;
	border: 1px solid #dfdfdf;
}

.endnote {
	margin: 1em;
}

.center {
	text-align: center;
}

.membersContainer {
	padding: 0 1em;
}