/* GLOBAL STYLE */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  letter-spacing: 0.6px;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "PT Sans", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
		"Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

button {
  font-family: -apple-system, BlinkMacSystemFont, "PT Sans", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
  "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

p, li {
  font-size: 18px;
}

a {
  text-decoration: none;
}

a:hover {
  transition: all 0.2s ease-out;
}

iframe.instagram-media:first-of-type {
	border: none !important;
}