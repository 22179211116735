button.button {
	font-size: 20px;
	color: white;
	background-color: #2a3239;
	border-radius: 4px;
	border: none;
	padding: 0.4em;
	margin: 0.5em;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

button.button:hover {
	cursor: pointer;
	background-color: #00983A;
}