.container {
	margin: 30px auto;
	max-width: 600px;
}

.container h2 {
	margin: 1em 15px;
}

.container p {
	margin: 0 15px;
}

.diamond {
	height: 150px;
	display: block;
	margin: 20px auto;
}

.silver {
	height: 50px;
	display: block;
	margin: 20px auto;
}

.bronze {
	width: 150px;
	display: block;
	margin: 20px auto;
}

.center {
	text-align: center;
}

.full-width {
	width: 100%;
}

@media screen and (max-width: 700px) {
	.container img.ubisoft {
		width: 200px;
		height: auto;
	}
	.container img.bnc {
		width: 100px;
		height: auto;
	}

	.container h3 {
		text-align: center;
	}
}
