.container {
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.container h3 {
	text-align: center;
	margin: 0 0 1em 0;
}

.container {
	margin: 0 1em 0 0;
}

.container a:hover {
	color: #27348B;
}

.container .group {
	margin: 0.5em 0;
}

.container label {
	margin: 0 1em;
}

.container a {
	color: black;
}

@media screen and (max-width: 740px) {
	.container {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 1em;
	}
}