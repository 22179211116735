footer.container {
	background-color: white;
	margin-top: 5em;
	bottom: 0;
	width: 100%;
	color: #818181;
	box-shadow: 0 -5px 10px 0px rgba(0, 0, 0, 0.07), 0 0 0 0 rgba(0, 0, 0, 0.05);
	text-align: center;
	z-index: 9998;
}

 .logosContainer {
	padding: 1em 1em;
}

 .socialMediaLogo {
	color: #818181;
	font-size: 26px;
	padding: 0.4em;
}

 .socialMediaLogo:hover {
	color: #27348B;
	transition: all 1s ease-out;
}

 .copyright {
	margin-bottom: 10px;
}

 .address {
	margin-bottom: 10px;
	font-size: 12px;
}

 .authors {
	font-size: 10px;
}

footer a {
	color: #818181
}

footer a:hover {
	color: #27348B;
}