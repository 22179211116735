.tile {
	display: block;
	margin: 0.5em;
	height: 100%;
	width: auto;
	border-radius: 4px;
	box-shadow: 0 1px 20px 0 rgba(1, 1, 1, 0.1), 0 1px 8px 0 rgba(0, 0, 0, 0.1);
	text-align: center;
}

.icon {
	font-size: 30px;
	padding-top: 1em;
}

.text {
	margin: 0.5em 1em 1em 1em;
	font-weight: bold;
}
