header.container {
  box-shadow: 0 1px 8px 0 rgba(1, 1, 1, 0.1), 0 1px 8px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
}

.navArea {
  display: flex;
  align-items: center;
  max-width: 960px;
  margin: 0 auto;
  padding: 10px 20px;
}

.navbar {
  margin-left: auto; 
  margin-right: 0;
}

.logoNameNavbar {
  text-decoration: none;
  font-size: 20px;
  color: inherit;
  margin-left: 15px;
}

.logoImg {
  display: block;
  width: 35px;
  height: 35px;
}

.button {
  display: none;
}

.rotate {
  animation-name: rotation;
  animation-duration: 3s;
}

@media screen and (max-width: 960px) {

  .animateFadeIn {
		animation-name: fade-in-left;
		animation-duration: 0.6s;
	}
  
  .navbar {
    display: none;
  }

  .navbar.active {
    color: #dfdfdf;
    z-index: 9999;
  }

  .active {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    min-height: 100vh;
    background-color: #2a3239;
    box-shadow: 0 40px 60px rgba(0, 0, 0, 0.1);
    padding: 40px 0 0 10px;
  }

  .button {
    display: flex;
    text-align: center;
    align-items: center;
    font-size: 30px;
    padding: 10px;
    margin-left: auto; 
    margin-right: 0;
  }

  .button.close {
    color: #dfdfdf;
    font-size: 40px;
    margin-right: 30px;
  }

  .button:hover {
    padding: 10px;
    border-radius: 30px;
    transition: all 0.2s ease-out;
    cursor: pointer;
  }

  .button.open:hover {
    background-color: #dfdfdf;
  }
  
  .button.close:hover {
    background-color: #8e8e92;
  }
}

@keyframes fade-in-left {
	from {
		opacity: 0;
		transform: translate3d(0, -100%, 0);
	}

	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}