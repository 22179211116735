.infosContainer {
	font-size: 18px;
	display: grid;
	grid-template-columns: 1% 90%;
	border: 1px solid #dfdfdf;
	margin: 1em;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
	padding: 1em;
}

.leftBanner {
	width: 7px;
}

.children {
	margin-left: 1em;
}