.link {
	color: black;
}

.link:hover {
	color:#c7ac00;
}

.list {
	padding: 5px 10px;
}