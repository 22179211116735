.desktopContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
}

.mobileContainer {
	display: block;
	margin: 0 auto;
}

.mobileContainer h3 {
	color: white;
	text-align: center;
	margin: 1em 0;
}

.mobileContainer ul {
	margin: 1em auto;
}

.mobileContainer li {
	text-decoration: none;
	list-style: none;
	text-align: center;
	margin: 0.5em auto;
}

.mobileContainer a {
	color: white;
}

.mobileContainer a:hover, 
.mobileContainer h3:hover {
	color: #00983A;
  transition: all 0.2s ease-out;
}