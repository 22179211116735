.container {
	display: block;
	margin: 0 auto;
	max-width: 960px;
}

.animate {
  animation-name: fade-in-up;
  animation-duration: 1s;
}

.container h2 {
  text-align: center;
  margin: 1em;
}

.container h3 {
  margin: 1em;
}

.container p {
  margin: 1em;
}

.container li {
  margin: 1em 1em 1em 2em;
}

@keyframes fade-in-up {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}