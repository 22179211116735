.navbarBarItem .menuItems {
  position: relative;
  font-size: 14px;
}

.navbarBarItem .menuItems a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}

.navbarBarItem .menuItems button {
  display: flex;
  align-items: center;
  color: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}

.navbarBarItem button span {
  margin-left: 3px;
}

.menuItems > a, .menuItems button {
  text-align: left;
  padding: 0.5em;
}

.navbarBarItem .menuItems a:hover,
.navbarBarItem .menuItems button:hover {
  background-color: #dfdfdf;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

.navbarBarItem .arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}